<template>
  <div>
    <validation-observer ref="simpleRules">

      <b-row>
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ $t('details') }}

              </h3>

              <button
                class="btn btn-gradient-primary"
                @click="addRold()"
              >
                {{ $t('saveData') }}
              </button>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('firstName') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Role"
                      rules="required"
                    >
                      <b-form-input
                        id="h-role-name"
                        v-model="Obj.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3" />

                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="login-password"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('details') }} :
                    </template>
                    <b-form-textarea
                      v-model="Obj.detail"
                      row="3"
                    />
                  </b-form-group>

                  <b-form-group
                    label-for="login-password"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <b-form-checkbox
                      v-model="Obj.set_default"
                      :value="1"
                      :unchecked-value="0"
                      class="custom-control-primary mb-1"
                    >
                      {{ $t('defaultValue') }}
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-model="Obj.show"
                      :value="1"
                      :unchecked-value="0"
                      class="custom-control-primary mb-1"
                    >
                      {{ $t('show') }} (admin)
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="Obj.show_cus"
                      :value="1"
                      :unchecked-value="0"
                      class="custom-control-primary"
                    >
                    {{ $t('show') }} ({{ $t('customer') }})
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col md="3" />
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('pricing') }}
              </h3>
            </div>

            <div class="p-2">
              <div class="row">
                <div class="col-md-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ $t('truck') }}
                        </th>
                        <th scope="col">
                          {{ $t('kilo') }}.
                        </th>
                        <th scope="col">
                          {{ $t('cubic') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in car.slice().reverse()"
                        :key="item.id"
                      >
                        <td>{{ item.name }}</td>

                        <td>
                          <b-form-input
                            v-model="item.kilo"
                          />
                        </td>
                        <td>
                          <b-form-input
                            v-model="item.queue"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ $t('ship') }}
                        </th>
                        <th scope="col">
                          {{ $t('kilo') }}.
                        </th>
                        <th scope="col">
                          {{ $t('cubic') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in ship.slice().reverse()"
                        :key="item.id"
                      >
                        <td>{{ item.name }}</td>

                        <td>
                          <b-form-input
                            v-model="item.kilo"
                          />
                        </td>
                        <td>
                          <b-form-input
                            v-model="item.queue"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  // BTable,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // BTable,
    BFormCheckbox,

  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      name: '',
      detail: '',
      set_default: 0,
      show: 0,
      fieldsCar: [
        {
          key: 'car', label: 'รถ', thStyle: { width: '10%', align: 'center' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'rateA', label: 'A', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateB', label: 'B', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateC', label: 'C', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateD', label: 'D', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateD1', label: 'D1', thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'rateS', label: 'S', thStyle: { width: '15%' }, thClass: 'text-center',
        },
      ],
      fieldsShip: [
        {
          key: 'ship', label: 'เรือ', thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'rateA', label: 'A', thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'rateB', label: 'B', thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'rateC', label: 'C', thStyle: { width: '30%' }, thClass: 'text-center',
        },
      ],
      itemsCar: [
        {
          car: 'กิโล.', rateA: '50', rateB: '70', rateC: '15', rateD: '20', rateD1: '200', rateS: '90',
        },
        {
          car: 'คิว.', rateA: '1000', rateB: '1500', rateC: '2000', rateD: '2500', rateD1: '3000', rateS: '3500',
        },

      ],
      itemsShip: [
        {
          ship: 'กิโล.', rateA: '50', rateB: '70', rateC: '15',
        },
        {
          ship: 'คิว.', rateA: '5500', rateB: '9000', rateC: '19000',
        },
      ],
      productarr: [],
      ship: [],
      car: [],
      arr: [],
      Obj: [{
        name: null,
        detail: null,
        set_default: null,
        show: null,
      }],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getProducttype()
    this.submit()
  },
  methods: {
    async getProducttype() {
      try {
        const { data: res } = await this.$http.get('/product_type/showlist')
        this.arr = res.data
        this.arr.sort((a, b) => {
            if (a.initial < b.initial) {
                return 1;
            } else if (a.initial > b.initial) {
                return -1;
            } else {
                return 0;
            }
        })
        this.car = this.arr.filter(ele => ele.type === 1)
        this.ship = this.arr.filter(ele => ele.type === 2)
      } catch (e) {
        console.log(e)
      }
    },
    addRold() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const totalarr = []
          this.arr.forEach(ele => {
            const obj = {
              product_type_id: ele.id,
              product_type_name: ele.name,
              product_type_detail: ele.detail,
              product_type_class: ele.type,
              product_type_kilo: ele.kilo,
              product_type_queue: ele.queue,
              product_type_initial: ele.initial,
              product_type_name_en: ele.name_en,
              product_type_name_cn: ele.name_cn,
            }
            totalarr.push(obj)
          })
          const Obj = {
            name: this.Obj.name,
            detail: this.Obj.detail,
            set_default: this.Obj.set_default,
            show: this.Obj.show,
            show_cus: this.Obj.show_cus,
            product_type: totalarr,
          }
          this.$http
            .post('/groupbyuser/list/storeAndupdate', Obj)
            .then(() => {
              this.Alert(true, 'เพิ่มข้อมูลสำเร็จ', '')
              this.$router.push({ name: 'admin-user-type' })
            })
            .catch(error => {
              this.Alert(false, 'เพิ่มข้อมูลไม่สำเร็จ', '')
              console.log(error)
              // this.SwalError(error.response.data.message)
            })
        }
      })

      // this.car.forEach(ele => {
      //   const obj = {
      //     product_type_id: ele.id,
      //     product_type_name: ele.name,
      //     product_type_detail: ele.detail,
      //     product_type_class: ele.type,
      //     product_type_kilo: ele.kilo,
      //     product_type_queue: ele.queue,
      //   }
      //   cararr.push(obj)
      // })
      // this.ship.forEach(ele => {
      //   const obj = {
      //     product_type_id: ele.id,
      //     product_type_name: ele.name,
      //     product_type_detail: ele.detail,
      //     product_type_class: ele.type,
      //     product_type_kilo: ele.kilo,
      //     product_type_queue: ele.queue,
      //   }
      //   shiparr.push(obj)
      // })
      // console.log(cararr)
      // console.log(this.arr)
    },
    // addRold() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       const obj = {
    //         name: this.name,
    //         detail: this.detail,
    //         set_default: this.set_default,
    //         show: this.show,
    //         car_a_kilo: this.itemsCar[0].rateA ? Number(this.itemsCar[0].rateA) : 0,
    //         car_a_q: this.itemsCar[1].rateA ? Number(this.itemsCar[1].rateA) : 0,
    //         car_b_kilo: this.itemsCar[0].rateB ? Number(this.itemsCar[0].rateB) : 0,
    //         car_b_q: this.itemsCar[1].rateB ? Number(this.itemsCar[1].rateB) : 0,
    //         car_c_kilo: this.itemsCar[0].rateC ? Number(this.itemsCar[0].rateC) : 0,
    //         car_c_q: this.itemsCar[1].rateC ? Number(this.itemsCar[1].rateC) : 0,
    //         car_d_kilo: this.itemsCar[0].rateD ? Number(this.itemsCar[0].rateD) : 0,
    //         car_d_q: this.itemsCar[1].rateD ? Number(this.itemsCar[1].rateD) : 0,
    //         car_d1_kilo: this.itemsCar[0].rateD1 ? Number(this.itemsCar[0].rateD1) : 0,
    //         car_d1_q: this.itemsCar[1].rateD1 ? Number(this.itemsCar[1].rateD1) : 0,
    //         car_s_kilo: this.itemsCar[0].rateS ? Number(this.itemsCar[0].rateS) : 0,
    //         car_s_q: this.itemsCar[1].rateS ? Number(this.itemsCar[1].rateS) : 0,
    //         ship_a_kilo: this.itemsShip[0].rateA ? Number(this.itemsShip[0].rateA) : 0,
    //         ship_a_q: this.itemsShip[1].rateA ? Number(this.itemsShip[1].rateA) : 0,
    //         ship_b_kilo: this.itemsShip[0].rateB ? Number(this.itemsShip[0].rateB) : 0,
    //         ship_b_q: this.itemsShip[1].rateB ? Number(this.itemsShip[1].rateB) : 0,
    //         ship_c_kilo: this.itemsShip[0].rateC ? Number(this.itemsShip[0].rateC) : 0,
    //         ship_c_q: this.itemsShip[1].rateC ? Number(this.itemsShip[1].rateC) : 0,
    //       }
    //       this.$http
    //         .post('/groupbyuser/list/storeAndupdate', obj)
    //         .then(() => {
    //           this.Alert(true, 'เพิ่มข้อมูลสำเร็จ', '')
    //           this.$router.push({ name: 'admin-user-type' })
    //         })
    //         .catch(error => {
    //           this.Alert(false, 'เพิ่มข้อมูลไม่สำเร็จ', '')
    //           console.log(error)
    //           // this.SwalError(error.response.data.message)
    //         })
    //     }
    //   })
    // },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
